import React from "react";

// Components
// Going to leave this component out until we can figure out the SVG situation.
// import ConnectedDevices from "../../SVG/ConnectedDevices"

// CSS
import "./WhyDI.css";

// images
import graph1Img from "../../assets/images/GRAPH1.png";
import graph2Img from "../../assets/images/GRAPH2.png";
import graph3Img from "../../assets/images/GRAPH3.png";

const WhyDI = () => {
  return (
    <>
      <div className="section-container" id="why-digital-infrastructure">
        <div className="container-padding">
          <h1 className="section-title-slim"> Why Digital Infrastructure?</h1>
          <div className="why-di-container">
            <div className="di-flex-section-wrapper">
              <div className="di-flex-section-title">
                BILLIONS OF CONNECTED DEVICES DEPEND ON DIGITAL INFRASTRUCTURE​
              </div>
              <img src={graph1Img} alt="" />
              {/* <ConnectedDevices /> */}
            </div>
            <div className="di-vertical-line"></div>
            <div className="di-flex-section-wrapper">
              <div className="di-flex-section-title">
                WE’RE CREATING COLOSSAL AMOUNTS OF DATA THAT REQUIRE STORAGE,
                PROCESSING AND DELIVERY​
              </div>
              <img src={graph2Img} alt="" />
            </div>
            <div className="di-vertical-line"></div>
            <div className="di-flex-section-wrapper">
              <div className="di-flex-section-title">
                SIGNIFICANT INFRASTRUCTURE IS NEEDED TO CONTINUE MEETING
                CONSUMER DEMAND​
              </div>
              <img src={graph3Img} alt="" />
            </div>
          </div>
          <p className="di-footer">
            There is no guarantee that StratCap Digital Infrastructure REIT, Inc.
            will be able to take advantage of these trends or that the trends
            will continue. 1) Accenture.com “Understanding Data in the New”,
            April 25, 2019. 2) Source: IDC White Paper, The Digitization of the
            World – From Edge to Core, May 2020. 3) Barclay’s Equity Research
            Report: U.S. Communications Infrastructure, July 15, 2020, includes
            towers, small cells and DAS. ​
          </p>
        </div>
      </div>
    </>
  );
};

export default WhyDI;
