import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowDown } from "@fortawesome/free-solid-svg-icons";

// Video
import heroVideo from "../../assets/videos/hero-HD.mp4";
import heroMobileVideo from "../../assets/videos/mobile-hero.mp4";

// Images
import posterDesktop from "../../assets/images/hero-placeholder.jpg";
import posterMobile from "../../assets/images/hero-mobile.jpg";

// CSS
import "./Hero.css";

const HeroVideo = (props) => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);
  const [transform, setTransform] = useState(false);

  const handleWindowSizeChange = () => {
    setInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    window.addEventListener("scroll", handleScroll, true);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
      window.removeEventListener("scroll", handleScroll, true);
    };
  });

  const handleScroll = () => {
    if (window.pageYOffset > 150) {
      setTransform(true);
    } else {
      setTransform(false);
    }
  };

  const renderDesktop = () => {
    return (
      <div className="hero_container" ref={props.refProp}>
        <div className="hero_content_container">
          <video
            autoPlay
            muted
            loop
            playsInline
            poster={posterDesktop}
            id="hero_video"
          >
            <source src={heroVideo} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <div className="hero-text-overlay">
            <span className="hero-text-overlay-text">
              INVESTING IN ESSENTIAL DIGITAL INFRASTRUCTURE ASSETS THAT KEEP US
              CONNECTED.
            </span>
          </div>
          <div className="hero-arrow-container">
            <span className={transform ? "hero-arrow-hide" : "hero-arrow-show"}>
              <FontAwesomeIcon icon={faArrowDown} className="fa-arrow-cust" />
            </span>
          </div>
        </div>
      </div>
    );
  };

  const renderMobile = () => {
    return (
      <div className="hero_container" ref={props.refProp}>
        <div className="hero_content_container">
          <video
            autoPlay
            muted
            loop
            playsInline
            poster={posterMobile}
            id="hero_video"
          >
            <source src={heroMobileVideo} type="video/mp4" />
            Your browser does not support HTML5 video.
          </video>
          <div className="hero-arrow-container">
            <span className={transform ? "hero-arrow-hide" : "hero-arrow-show"}>
              <FontAwesomeIcon icon={faArrowDown} className="fa-arrow-cust" />
            </span>
          </div>
        </div>
      </div>
    );
  };

  return <>{innerWidth <= 600 ? renderMobile() : renderDesktop()}</>;
};

export default HeroVideo;
