import React, { useState } from "react";

// MUI imports
import Backdrop from "@material-ui/core/Backdrop";
import { makeStyles } from "@material-ui/core/styles";

// CSS
import "./Team.css";

// Components
import BioPage from "../bio-page/BioPage";

const useStyles = makeStyles((theme) => ({
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: "#fff",
    backgroundColor: "rgba(0, 0, 0, 0.9)",
    backdropFilter: "blur(11px)",
  },
}));

const TeamCard = (props) => {
  const { name, title, img, bio1, bio2, bio3, bio4, bio5, bio6 } = props;

  const classes = useStyles();

  const [open, setOpen] = useState(false);
  const handleClose = () => {
    setOpen(false);
  };
  const handleToggle = () => {
    setOpen(!open);
  };

  return (
    <>
      <div className="tc-wrapper">
        <div className="tc-img-wrapper">
          <img src={img} alt="" />
        </div>
        <p className="tc-name">{name}</p>
        <p className="tc-title">{title}</p>
        <span className="tc-bio-link" onClick={handleToggle}>
          click here for bio
        </span>
        <Backdrop
          className={classes.backdrop}
          open={open}
          // onClick={handleClose}
        >
          <BioPage
            name={name}
            title={title}
            img={img}
            bio1={bio1}
            bio2={bio2}
            bio3={bio3}
            bio4={bio4}
            bio5={bio5}
            bio6={bio6}
            close={handleClose}
          />
        </Backdrop>
      </div>
    </>
  );
};

export default TeamCard;
