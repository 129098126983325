import React, { useReducer, createContext, useEffect } from "react";

import { BrowserRouter as Router, Route } from "react-router-dom";

import "./App.css";

import LoginPage from "./login/LoginPage";
import HomePage from "./home/Home";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// Auth Context will pass the auth state from this component to any other component that needs it.
export const AuthContext = createContext();
const initialState = {
  isAuthenticated: false,
  user: null,
  token: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "LOGIN":
      // localStorage.setItem("user", JSON.stringify(action.payload.user));
      // localStorage.setItem("token", JSON.stringify(action.payload.token));
      return {
        ...state,
        isAuthenticated: true,
        user: action.payload.user,
        token: action.payload.token,
      };
    case "LOGOUT":
      // localStorage.clear();
      sessionStorage.clear();
      return {
        ...state,
        isAuthenticated: false,
        user: null,
      };
    default:
      return state;
  }
};

function App() {
  const [state, dispatch] = useReducer(reducer, initialState);
  useEffect(() => {
    const user = sessionStorage.getItem("profileId");
    const token = sessionStorage.getItem("token");

    if (user && token) {
      dispatch({
        type: "LOGIN",
        payload: {
          user,
          token,
        },
      });
    }
  }, []);
  return (
    <Router>
      <AuthContext.Provider value={{ state, dispatch }}>
        <Route exact path="/">
          {!state.isAuthenticated ? <LoginPage /> : <HomePage />}
        </Route>
        <Route path="/ss">
          {!state.isAuthenticated ? <LoginPage /> : <HomePage />}
        </Route>
      </AuthContext.Provider>
    </Router>
  );
}

export default App;
