import React from "react";

import "./Modal.css";

const Modal = ({ show, close, path, fItemId }) => {
  const baseVideoUrl = process.env.REACT_APP_SCAPI;
  const largeImgUrl = process.env.REACT_APP_LARGE_THUMBNAIL_URL;
  let poster = `${largeImgUrl}${fItemId}`;
  let videoPath = encodeURI(path);
  const videoSrc = baseVideoUrl + videoPath;
  // console.log(videoSrc);
  // console.log(videoPath);
  // console.log(baseVideoUrl + videoPath)

  return (
    <>
      <div id="myModal" className={show ? "modal-show" : "modal-hide"}>
        <div className="modal-content-cust">
          <div className="modal-header-cust">
            <span className="modal-close-cust" onClick={close}>
              &times;
            </span>
            <h5 className="fw-4">Media Player</h5>
          </div>
          <div className="modal-body-cust">
            {path !== null ? (
              <video
                key={videoSrc} // KEY must be included for this component to work.
                controls
                poster={poster}
                autoPlay
                className="modal-video-mod"
              >
                <source src={videoSrc} type="video/mp4" />
                <source src={videoSrc} type="video/ogg" />
                Your browser does not support the video tag.
              </video>
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default Modal;
