import React from "react";

// CSS
import "./About.css";

// images
import ratesImg from "../../assets/images/ABOUT-ICON1.png";
import incomeImg from "../../assets/images/ABOUT-ICON2.png";
import growthImg from "../../assets/images/ABOUT-ICON3.png";
import hedgeImg from "../../assets/images/ABOUT-ICON4.png";

const About = () => {
  return (
    <>
      <div className="section-container" id="about">
        <div className="container-padding">
          <h1 className="section-title-slim">
            About StratCap Digital Infrastructure REIT, Inc.
          </h1>
          <p>
            StratCap Digital Infrastructure REIT, Inc. is designed to capitalize
            on the ongoing demand for wireless communications and connectivity
            by acquiring essential digital infrastructure assets like cell
            towers, edge computing facilitates, digital exchange centers, fiber
            networks and more. These assets have become integral in our daily
            lives and support the connectivity and data that we have come to
            rely on. The REIT provides access to this dynamic market sector
            through a highly experienced management team with the intent to
            provide investors with attractive risk-adjusted returns as well as.
            ​
          </p>
          <div className="about-image-row-wrapper">
            <div className="about-image-wrapper">
              <img src={ratesImg} alt="" className="about-img-margin" />
              <div className="about-box-container-gray">
                <h5 className="black">
                  Attractive Distribution Rates<sup>1</sup>​
                </h5>
                <span className="t-small">
                  Relative to traditional fixed-rate products. ​
                </span>
              </div>
            </div>
            <div className="about-image-wrapper">
              <img src={incomeImg} alt="" className="about-img-margin" />
              <div className="about-box-container-lb">
                <h5 className="black">
                  Tax-Efficient Income<sup>1,2</sup>​
                </h5>
                <span className="t-small">
                  Investors may be able to defer potential income taxes and
                  reclassify a portion of the tax liability to the lower capital
                  gains tax rate at a future date. ​
                </span>
              </div>
            </div>
            <div className="about-image-wrapper">
              <img src={growthImg} alt="" className="about-img-margin" />
              <div className="about-box-container-db">
                <h5 className="black">
                  Growth Potential<sup>3</sup>​
                </h5>
                <span className="t-small">
                  Through strategic acquisitions, lease extensions, automatic
                  rent escalators, lease-up of additional space, 5G upgrades,
                  and more. ​
                </span>
              </div>
            </div>
            <div className="about-image-wrapper">
              <img src={hedgeImg} alt="" className="about-img-margin" />
              <div className="about-box-container-slate">
                <h5 className="black">
                  A Potential Hedge​ Against Inflation<sup>3</sup>​
                </h5>
                <span className="t-small">
                  Given that real asset values generally keep pace with rising
                  inflation. ​
                </span>
              </div>
            </div>
          </div>
          <p className="about-footer">
            1) Distributions are not guaranteed and subject to change. Until
            proceeds from the offering are fully invested, distributions may be
            from a return of capital in anticipation of future cash flows which
            may reduce the capital invested in assets and negatively impact the
            investment. Please refer to the private placement memorandum for a
            complete discussion of costs and risks associated with the offering.
            ​ 2) The depreciation associated with digital infrastructure assets
            within our product offerings is expected to result in either all, or
            a portion of, distribution payments being classified as a return of
            capital, which would have the effect of lowering the overall cost
            basis. Upon liquidation of shares/units, any gains based on the
            adjusted cost basis may be subject to capital gains, depreciation
            recapture or a combination thereof. Each individual's tax situation
            and tax rate may be different. This information should not be
            construed as tax advice nor provides any opinion on, or discussion
            of, tax law. Investors should seek the advice of tax council before
            making any investment. Tax rates may be subject to change.​ 3) There
            is no guarantee, implied or actual, that this objective will be
            achieved. Actual results may differ. ​
          </p>
        </div>
      </div>
    </>
  );
};

export default About;
