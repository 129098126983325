import React, { useEffect, useState } from "react";
import axios from "axios";

// Components
import Fitems from "./Fitems";
import Modal from "../modal/Modal";

// MUI imports
import { makeStyles } from "@material-ui/core/styles";
import { createMuiTheme } from "@material-ui/core/styles";
import { ThemeProvider } from "@material-ui/styles";
import PropTypes from "prop-types";
import AppBar from "@material-ui/core/AppBar";
import Tabs from "@material-ui/core/Tabs";
import Tab from "@material-ui/core/Tab";
import Box from "@material-ui/core/Box";
// import CircularProgress from "@material-ui/core/CircularProgress";
import Skeleton from "@material-ui/lab/Skeleton";
import Grid from "@material-ui/core/Grid";

// CSS
import "./InformationCenter.css";

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
    >
      <Box p={3} className="fitem_wrapper_mui">
        {children}
      </Box>
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  // index: PropTypes.any.isRequired,
  value: PropTypes.any.isRequired,
};

function a11yProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    "aria-controls": `scrollable-auto-tabpanel-${index}`,
  };
}

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    width: "100%",
    backgroundColor: theme.palette.background.paper,
  },
  progbar: {
    display: "flex",
    "& > * + *": {
      marginLeft: theme.spacing(2),
    },
  },
}));

const theme = createMuiTheme({
  palette: {
    primary: {
      main: "#4c7aa7",
    },
    secondary: {
      main: "#58a8d5",
    },
  },
});

const InformationCenterMUI = () => {
  useEffect(() => {
    listProducts();
  }, []);

  const classes = useStyles();
  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const baseVideoUrl = process.env.REACT_APP_SCAPI;
  const largeImgUrl = process.env.REACT_APP_LARGE_THUMBNAIL_URL;
  const ROOT_URL = process.env.REACT_APP_API_URL;

  // Use two state objects. One for the products (SWIF, SDCF, etc.).
  // The second is for the fItems for each product.
  const [products, setProducts] = useState([]);
  const [fItems, setFitems] = useState([]);
  const [activeTab, setActiveTab] = useState(""); // When tab is clicked, this object gets populated and allows the tab to be "active"
  const [modal, setModal] = useState(false); // Controls the modal show/hide functionality with a simple boolean.
  // const [modalSku, setModalSku] = useState(""); // When we use the onclick function we store the sku, which is used in the modal to populate the correct video.
  const [fitemId, setFitemId] = useState("");
  const [itemPath, setItemPath] = useState("");
  // Loading for infocenter content
  const [isLoading, setIsLoading] = useState(false);

  // SP call that grabs all of the products to display in tabs
  function listProducts() {
    let private_placement_id = parseInt(
      sessionStorage.getItem("privatePlacementId")
    );
    let product_id = sessionStorage.getItem("productId");
    let view_only = sessionStorage.getItem("viewOnly");
    let profile_id = parseInt(sessionStorage.getItem("profileId"));
    var AuthData = {
      is_investor_portal: true,
      product_id: product_id,
      profile_id: profile_id,
      private_placement_id: private_placement_id,
      view_only: view_only,
    };

    var spFIInput = {
      spName: "ProductDataVault_ListSections",
      jsonInput: JSON.stringify(AuthData),
    };
    var FundData = JSON.stringify(spFIInput);
    var authenticationToken = sessionStorage.getItem("token");
    axios({
      method: "post",
      url: ROOT_URL,
      data: FundData,
      headers: {
        "Content-Type": "application/json",
        Authorization: authenticationToken,
      },
    })
      .then((response) => {
        const loginResponse = JSON.parse(response.data);
        // console.log(loginResponse.Rows[0].investor_portal_section_id);
        setProducts(loginResponse.Rows); // sets all the products in state
        console.log(loginResponse.Rows);
        listFitems(loginResponse.Rows[0].investor_portal_section_id); // default first product when page loads
      })

      .catch((error) => {
        console.log("SP Call Error: " + error);
      });
  }

  // SP call that takes the product ID in and renders the fItems according to that ID
  function listFitems(fItem) {
    setIsLoading(true);
    setActiveTab(fItem);
    // console.log(fItems);
    // set the fItems array in state to empty before making the call to empty out the previous content.
    setFitems([]);
    let sessionItems = JSON.parse(sessionStorage.getItem(fItem));
    // console.log(sessionItems);
    if (sessionItems) {
      setFitems(sessionItems);
      setIsLoading(false);
      // console.log(sessionItems);
    } else {
      const ROOT_URL = process.env.REACT_APP_API_URL;
      let product_id = parseInt(sessionStorage.getItem("productId"));
      let profile_id = parseInt(sessionStorage.getItem("profileId"));
      let view_only = sessionStorage.getItem("viewOnly");
      var RequestData = {
        view_only: view_only,
        product_id: product_id,
        profile_id: profile_id,
        investor_portal_section_id: fItem,
      };
      var spFIInput = {
        spName: "ProductDataVault_ListSectionFItems",
        jsonInput: JSON.stringify(RequestData),
      };
      var FundData = JSON.stringify(spFIInput);
      var authenticationToken = sessionStorage.getItem("token");

      axios({
        method: "post",
        url: ROOT_URL,
        data: FundData,
        headers: {
          "Content-Type": "application/json",
          Authorization: authenticationToken,
        },
      })
        .then((response) => {
          setIsLoading(false);
          const loginResponse = JSON.parse(response.data);
          // console.log(response);
          setFitems(loginResponse.Rows);
          let jsonRows = JSON.stringify(loginResponse.Rows);
          sessionStorage.setItem(fItem, jsonRows);
        })

        .catch((error) => {
          console.log("SP Call Error: " + error);
        });
    }
  }

  // After the state is set onload with the products, loop over it to display the tabs.
  // We use the products ID in the onclick method that allows us to display the proper fItems.
  //   MUI Map for tabs
  const productArrayMUI = products.map(function (prodList) {
    // console.log(prodList);
    let index = prodList.investor_portal_section_id;
    return (
      <Tab
        key={index}
        label={prodList.section_name}
        value={index}
        {...a11yProps(index)}
        onClick={() => listFitems(index)}
      />
    );
  });

  // This takes the state object of fItems that gets populated from the SP call and loops over them to display each item.
  //   MUI Map for fitems to populate the tab body
  const fItemsArrayMUI = fItems.map(function (itemsList) {
    // console.log(itemsList);
    return (
      <Grid item xs={6} sm={4} md={3} key={itemsList.item_sku}>
        <Fitems
          fitemId={itemsList.fitem_id}
          itemSku={itemsList.item_sku}
          itemName={itemsList.item_name}
          isVideo={itemsList.is_video}
          isHosted={itemsList.hosted_video}
          downloadUrl={itemsList.download_url}
          modalClick={(e) => handleClick(e, itemsList.item_sku)}
        />
      </Grid>
    );
  });

  // Render Skeletons
  const renderSkeletons = () => {
    return (
      <Grid container spacing={2} className="skeletons-padding">
        <Grid item xs={12} sm={4} md={3}>
          <Skeleton variant="rect" height={150} />
          <Skeleton variant="text" />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Skeleton variant="rect" height={150} />
          <Skeleton variant="text" />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Skeleton variant="rect" height={150} />
          <Skeleton variant="text" />
        </Grid>
        <Grid item xs={12} sm={4} md={3}>
          <Skeleton variant="rect" height={150} />
          <Skeleton variant="text" />
        </Grid>
      </Grid>
    );
  };

  // handles the modal click. Takes the sku to pass through to the modalSku object for storing.
  function handleClick(e, itemSku) {
    console.log(itemSku);
    e.preventDefault();
    itemPathRequest(itemSku);
  }

  const itemPathRequest = (sku) => {
    let private_placement_id = parseInt(
      sessionStorage.getItem("privatePlacementId"),
      10
    );
    let product_id = sessionStorage.getItem("productId");
    let investor_mode = sessionStorage.getItem("investorMode");
    let profile_id = parseInt(sessionStorage.getItem("profileId"), 10);
    let item_sku = sku;
    let view_only = sessionStorage.getItem("viewOnly");
    let requestData = {
      item_sku: item_sku,
      investor_mode: investor_mode,
      view_only: view_only,
      product_id: product_id,
      private_placement_id: private_placement_id,
      profile_id: profile_id,
    };
    let spRequest = {
      spName: "InvestorPortal_SelectFItem",
      jsonInput: JSON.stringify(requestData),
    };
    let spRequestData = JSON.stringify(spRequest);
    let authenticationToken = sessionStorage.getItem("token");

    axios({
      method: "post",
      url: ROOT_URL,
      data: spRequestData,
      headers: {
        "Content-Type": "application/json",
        Authorization: authenticationToken,
      },
    })
      .then((response) => {
        const success_response = JSON.parse(response.data);
        // console.log(success_response.Rows[0]);
        // const sku = success_response.Rows[0].item_sku;
        const url = success_response.Rows[0].download_url;
        const isHosted = success_response.Rows[0].hosted_video;
        const path = success_response.Rows[0].document_path;
        const fItemId = success_response.Rows[0].fitem_id;

        setItemPath(path);
        setFitemId(fItemId);
        if (isHosted) {
          setModal(true);
        } else if (isHosted === false) {
          window.open(url);
        }
      })

      .catch((error) => {
        console.log(error);
      });
  };

  // this runs on the close button on the modal. We have to set modalFitem to ('') or else the video will keep playing when you close the modal.
  function renderClose() {
    setModal(false);
    setItemPath("");
    setFitemId("");
  }

  const renderContent = () => {
    if (products.length === 0) {
      return <h1 className="t-center fw-3 d-blue">Coming soon</h1>;
    } else {
      return (
        <div className="content_wrapper">
          {/* <div className="product_tab_wrapper">{productArray}</div>
          <div className="fitem_wrapper">{fItemsArray}</div> */}

          <div className={`${classes.root} container-padding`}>
            <AppBar position="static" color="default">
              <Tabs
                value={activeTab}
                indicatorColor="primary"
                textColor="primary"
                variant="scrollable"
                scrollButtons="auto"
                aria-label="scrollable auto tabs example"
              >
                {productArrayMUI}
              </Tabs>
            </AppBar>
            <TabPanel value={activeTab} className="tab-panel-cust">
              {isLoading ? (
                renderSkeletons
              ) : (
                <Grid container spacing={2}>
                  {fItemsArrayMUI}
                </Grid>
              )}
            </TabPanel>
          </div>

          <Modal
            show={modal}
            close={renderClose}
            path={itemPath}
            fItemId={fitemId}
          />
        </div>
      );
    }
  };

  return (
    <ThemeProvider theme={theme}>
      <div className="section-container" id="information-center">
        <div>
          <h1 className="section-title-slim">Information Center</h1>
          {renderContent()}
        </div>
      </div>
    </ThemeProvider>
  );
};

export default InformationCenterMUI;
