import React, { useState, useEffect, useContext } from "react";
import { useHistory } from "react-router-dom";
import axios from "axios";
import qs from "qs";

// Components
import { AuthContext } from "../App";
import InvestorBox from "./InvestorBox";
import AdvisorBox from "./AdvisorBox";
import LoginFooter from "./LoginFooter";

// CSS
import "./Login.css";
import "../components/modal/Modal.css";

// Assets
import swifLogo from "../assets/images/StratCap-DIR-Logo.png";
import investorImg from "../assets/images/INV.png";
import fpImg from "../assets/images/FP.png";
import tax1Form from "../assets/files/TY21_SWIF II__F8937_Common - Investors.pdf";
import tax2Form from "../assets/files/TY22_SWIF II_F8937_Common - Investors.pdf";
import tax3Form from "../assets/files/TY23_SWIF II_F8937_Common_Final Package- Signed.pdf";

const LoginPage = () => {
  const ROOT_URL = process.env.REACT_APP_API_URL;
  let history = useHistory();

  const { dispatch } = useContext(AuthContext);

  const initialState = {
    isSubmitting: false,
    errorMessage: null,
  };

  const [data, setData] = useState(initialState);
  const [investor, setInvestor] = useState(true);
  const [modalShow, setModalShow] = useState(false);

  const SSOLogin = (token) => {
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
    let product_key = process.env.REACT_APP_PRODUCT_KEY;
    let ssauthtoken = token;
    let requestData = { product_key: product_key, token: ssauthtoken };
    let spRequest = {
      spName: "InvestorPortal_SSOLogin",
      jsonInput: JSON.stringify(requestData),
      headers: { "Content-Type": "application/json" },
    };
    // let spRequestBundle = JSON.stringify(spRequest);
    axios({ method: "post", url: ROOT_URL, data: spRequest })
      .then((response) => {
        const login_response = JSON.parse(response.data);
        if (login_response.Rows.length === 1) {
          sessionStorage.setItem(
            "viewAssetGallery",
            login_response.Rows[0].view_asset_gallery
          );
          sessionStorage.setItem(
            "investorMode",
            login_response.Rows[0].investor_mode
          );
          sessionStorage.setItem(
            "productId",
            login_response.Rows[0].product_id
          );
          sessionStorage.setItem(
            "profileId",
            login_response.Rows[0].profile_id
          );
          sessionStorage.setItem(
            "privatePlacementId",
            login_response.Rows[0].private_placement_id
          );
          sessionStorage.setItem(
            "reducedInvestorPortal",
            login_response.Rows[0].reduced_investor_portal
          );
          sessionStorage.setItem(
            "allowESignature",
            login_response.Rows[0].allow_esignature
          );
          sessionStorage.setItem("viewOnly", login_response.Rows[0].view_only);
          sessionStorage.setItem(
            "realLoggedInProfileId",
            login_response.Rows[0].real_logged_in_profile_id
          );
          sessionStorage.setItem("token", response.headers.authorization);
          dispatch({ type: "LOGIN", payload: login_response.Rows[0] });
          history.push("/");
        } else {
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: login_response.error_message,
          });
          console.log(login_response.error_message);
        }
        return { resp: response };
      })
      .catch((error) => {
        console.log(error);
      });
  };

  useEffect(() => {
    const ssValue = qs.parse(window.location.search, {
      ignoreQueryPrefix: true,
    });
    // console.log(Object.keys(ssValue))
    // console.log(ssValue)

    if (Object.keys(ssValue).length !== 0) {
      // if the query string in the URL exists, run the SSOLogin function
      SSOLogin(ssValue.id); // pass the host and SSO value
    }
  }, []);

  // investor login -- financial advisor login switch
  const renderLoginBox = () => {
    if (investor) {
      return (
        <div className="login-login-box">
          <InvestorBox />
        </div>
      );
    } else {
      return (
        <div className="login-login-box">
          <AdvisorBox />
        </div>
      );
    }
  };
  return (
    <>
      {data.isSubmitting ? (
        <div className="loader-container">
          <div className="loader"></div>
        </div>
      ) : (
        <div className="login-page-wrapper">
          <div className="login-header">
            <div style={{ maxWidth: "210px" }}>
              <img src={swifLogo} alt="sdcf logo" className="sdcf-login-logo" style={{width: "100%"}} />
            </div>
          </div>
          <div className="login-lower-content-flex-container">
            <div className="login-lower-title-wrapper">
              {data.errorMessage && (
                <p className="form-error">{data.errorMessage}</p>
              )}
              {/* <p className="form-error">ERROR AREA</p> */}
            </div>
            <div className="login-choice-box-wrapper">
              <div
                className={
                  investor ? "login-choice-box-checked" : "login-choice-box"
                }
                onClick={() => {
                  setInvestor(true);
                }}
              >
                <img src={investorImg} alt="investor" />
                <span>Investor</span>
              </div>
              <div
                className={
                  !investor ? "login-choice-box-checked" : "login-choice-box"
                }
                onClick={() => {
                  setInvestor(false);
                }}
              >
                <img src={fpImg} alt="financial professional" />
                <span>Financial Professional</span>
              </div>
            </div>
            <div className="login-lower-loginbox-wrapper">
              {renderLoginBox()}
              <p className="login-vosab-text-wrapper">
                <a
                  // href="https://www3.financialtrans.com/tf/Vision"
                  href={
                    investor
                      ? "https://secureaccountview.com/BFWeb/clients/strategicfunds/index"
                      : "https://www3.financialtrans.com/tf/Vision"
                  }
                  className="login-vosab-text"
                  target="_new"
                  rel="noopener noreferrer"
                >
                  View Your Online Statements and Account Balances
                </a>
              </p>
              <p
                className="login-vosab-text-wrapper login-vosab-text hover"
                onClick={() => setModalShow(true)}
              >
                View Tax Information
              </p>
            </div>
          </div>
          <LoginFooter />
          {/* <!-- The Modal --> */}
          <div
            id="myModal"
            className={modalShow === false ? "modal-hide" : "modal-show"}
          >
            {/* <!-- Modal content --> */}
            <div className="modal-content-cust-login">
              <div className="modal-header-cust">
                <span
                  className="modal-close-cust"
                  onClick={() => setModalShow(!modalShow)}
                >
                  &times;
                </span>
                <h5 className="fw-4">Tax Information</h5>
              </div>
              <div className="modal-body-cust">
                <div style={{ padding: "40px 20px" }}>
                  <ul
                    style={{ listStyleType: "none", margin: "0", padding: "0" }}
                  >
                    <li>
                      <a
                        href={tax1Form}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: "1.2rem" }}
                      >
                        Tax Year 2021 – F8937 Common Shareholders Tax Form
                      </a>
                    </li>
                    <li>
                      <a
                        href={tax2Form}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: "1.2rem" }}
                      >
                        Tax Year 2022 – F8937 Common Shareholders Tax Form
                      </a>
                    </li>
                    <li>
                      <a
                        href={tax3Form}
                        target="_blank"
                        rel="noopener noreferrer"
                        style={{ fontSize: "1.2rem" }}
                      >
                        Tax Year 2023 – F8937 Common Shareholders Tax Form
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="close_button_bottom">
                <button
                  onClick={() => setModalShow(!modalShow)}
                  className="modal_close_button"
                >
                  Close
                </button>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default LoginPage;
