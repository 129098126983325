import React, { useState, useContext, useEffect } from "react";
import axios from "axios";
import { AuthContext } from "../App";

// MUI imports
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Checkbox from "@material-ui/core/Checkbox";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#0096c8",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0096c8",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0096c8",
      },
    },
  },
})(TextField);

const AdvisorBox = () => {
  const ROOT_URL = process.env.REACT_APP_API_URL;
  const PROD_KEY = process.env.REACT_APP_PRODUCT_KEY;
  const PASSWORD_RESET = process.env.REACT_APP_ADVISOR_PASSWORD_RESET_URL;
  const REGISTER_URL = process.env.REACT_APP_ADVISOR_PORTAL_LOGIN_URL;

  useEffect(() => {
    const userName = localStorage.getItem("username");
    const userPass = localStorage.getItem("userpass");
    if (userName && userPass) {
      setData({
        ...data,
        email: userName,
        password: userPass,
      });
      setChecked(true);
    }
  }, []);

  const { dispatch } = useContext(AuthContext);

  const initialState = {
    email: "",
    password: "",
    isSubmitting: false,
    errorMessage: null,
  };

  const [data, setData] = useState(initialState);
  const [successMessage, setSuccessMessage] = useState(false);
  const [checked, setChecked] = useState(false);
  const [buttonText, setButtonText] = useState("LOG IN");

  const handleInputChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleCheckChange = (event) => {
    setChecked(event.target.checked);
    console.log(checked);
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();
    setButtonText("");
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
    const LoginInput = {
      product_key: PROD_KEY,
      email: data.email,
      login_password: data.password,
    };
    const spInput = {
      spName: "InvestorPortal_SCORELogin",
      jsonInput: JSON.stringify(LoginInput),
    };
    const inputData = JSON.stringify(spInput);

    axios({
      method: "post",
      url: ROOT_URL,
      data: inputData,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        setButtonText("SUCCESS!");
        const login_response = JSON.parse(response.data);
        console.log(login_response);
        if (login_response.Rows.length === 1) {
          sessionStorage.setItem(
            "investorMode",
            login_response.Rows[0].investor_mode
          );
          sessionStorage.setItem(
            "productId",
            login_response.Rows[0].product_id
          );
          sessionStorage.setItem(
            "profileId",
            login_response.Rows[0].profile_id
          );
          sessionStorage.setItem(
            "privatePlacementId",
            login_response.Rows[0].private_placement_id
          );
          sessionStorage.setItem(
            "reducedInvestorPortal",
            login_response.Rows[0].reduced_investor_portal
          );
          sessionStorage.setItem("token", response.headers.authorization);
          sessionStorage.setItem("viewOnly", login_response.Rows[0].view_only);
          sessionStorage.setItem(
            "realLoggedInProfileId",
            login_response.Rows[0].real_logged_in_profile_id
          );
          if (checked === true) {
            localStorage.setItem("username", data.email);
            localStorage.setItem("userpass", data.password);
          } else {
            localStorage.clear();
          }
          dispatch({ type: "LOGIN", payload: login_response.Rows[0] });
        } else {
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: login_response.error_message,
          });
          setButtonText("LOG IN");
        }
        return { resp: response };
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const resetScorePassword = () => {
    const AuthData = {
      email: data.email,
      reset_password_url: PASSWORD_RESET,
      extra_info: `dest=ip,pkey=${PROD_KEY}`
    };
    const spInput = {
      spName: "Person_RequestResetPassword",
      jsonInput: JSON.stringify(AuthData),
    };
    const inputData = JSON.stringify(spInput);

    axios({
      method: "post",
      url: ROOT_URL,
      data: inputData,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        let resp = JSON.parse(response.data);
        if (resp.error_message) {
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: resp.error_message,
          });
        } else {
          setSuccessMessage(true);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const renderLoginButton = () => {
    if (buttonText === "LOG IN" || buttonText === "SUCCESS!") {
      return (
        <button disabled={data.isSubmitting} className="advisor-submit-button">
          {buttonText}
        </button>
      );
    } else {
      return (
        <button className="advisor-submit-button">
          <div className="login-loading-dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      );
    }
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <CssTextField
          label="Account Email"
          type="email"
          value={data.email}
          onChange={handleInputChange}
          name="email"
          className="login-input-box"
        />
        <CssTextField
          label="Password"
          type="password"
          value={data.password}
          onChange={handleInputChange}
          name="password"
          className="login-input-box"
        />
        <p className="no-margin checkbox-para">
          <Checkbox
            checked={checked}
            onChange={handleCheckChange}
            color="default"
            size="small"
            inputProps={{ "aria-label": "checkbox with default color" }}
          />
          <span className="t-small">Remember me on this device</span>
        </p>
        <p className="t-small hover" onClick={resetScorePassword}>
          Forgot/Reset Password?
        </p>
        {data.errorMessage && <p className="form-error">{data.errorMessage}</p>}
        {successMessage && (
          <p className="form-success">
            Instructions have been sent to the Account Email below. Please check
            your email in a few minutes for further Password instructions.
          </p>
        )}
        <div className="login-button-wrapper">{renderLoginButton()}</div>
      </form>
      <p className="register-here-p">
        No account?{" "}
        <a href={REGISTER_URL} className="register-here-link d-blue fw-6">
          Register here.
        </a>
      </p>
    </>
  );
};

export default AdvisorBox;
