import React from "react";

import "./Footer.css";

import stratCapLogo from "../../assets/images/StratCap-Securities-Logo.png";

const Footer = () => {
  return (
    <>
      <div className="section-container">
        <div className="container-padding footer-main-container">
          <h6 className="t-small">
            The use of this site is expressly for accredited investor use only
            and is not to be distributed or copied.
          </h6>
          <p className="fw-7">
            THIS SITE AND ITS CONTENT DOES NOT CONSTITUTE AN OFFER TO SELL OR A
            SOLICITATION OF AN OFFER TO BUY ANY SECURITY. AN OFFERING IS MADE
            ONLY BY A CONFIDENTIAL PRIVATE PLACEMENT MEMORANDUM ("PPM"). THIS
            MATERIAL MUST BE READ IN CONJUNCTION WITH THE PPM IN ORDER TO
            UNDERSTAND FULLY ALL THE IMPLICATIONS AND RISKS OF THE OFFERING OF
            SECURITIES TO WHICH IT RELATES AND MUST NOT BE RELIED UPON TO MAKE
            AN INVESTMENT.
          </p>
          <p>
            <span className="fw-7">
              THIS MATERIAL MUST BE PRECEDED OR ACCOMPANIED BY A PPM.
            </span>{" "}
            THE SECURITIES DESCRIBED HEREIN AND IN THE PPM HAVE NOT BEEN, AND
            WILL NOT BE, REGISTERED UNDER THE SECURITIES ACT OF 1933, AS
            AMENDED, THE SECURITIES LAWS OF ANY U.S. STATE OR THE SECURITIES
            LAWS OF ANY OTHER JURISDICTION. STRATCAP DIGITAL INFRASTRUCTURE
            REIT, INC. (THE "REIT") WILL NOT BE REGISTERED UNDER THE INVESTMENT
            COMPANY ACT OF 1940, AS AMENDED. NEITHER THE SECURITIES AND EXCHANGE
            COMMISSION NOR ANY OTHER U.S. OR NON-U.S. SECURITIES REGULATORY
            AUTHORITY HAS PASSED UPON THE ACCURACY OR ADEQUACY OF THIS DOCUMENT
            OR APPROVED OR DISAPPROVED OF THE PROSPECTIVE INVESTMENT DESCRIBED
            HEREIN. SIGNIFICANT RESTRICTIONS, UNDER BOTH APPLICABLE LAW AND THE
            FUND’S LIMITED LIABILITY COMPANY AGREEMENT, EXIST ON THE
            TRANSFERABILITY OF THE REIT’S SECURITIES.
            <span className="fw-7">
              FINANCIAL PROFESSIONALS MUST HAVE A SUBSTANTIVE PRE-EXISTING
              RELATIONSHIP WITH ANY POTENTIAL INVESTORS. GENERAL SOLICITATION TO
              PROSPECTIVE CLIENTS IS NOT ALLOWED.
            </span>
          </p>
          <p>
            <span className="fw-7">PLEASE NOTE:</span>The article and video
            links on this page are being made available so that you will have an
            opportunity to obtain information from third parties on their
            websites. Links are provided solely as a convenience and no
            conclusions should be made with regard to the offering. Companies
            mentioned in these third party articles and videos, unless otherwise
            noted, have no relationship or affiliation with the respective
            offering indicated. We are not responsible for the content on any
            linked site or any link contained in a linked site. There is no
            assurance that investors will earn a return based upon the
            statistics, trends and/or opinions presented in these third party
            materials. This material may or may not represent statistics, trends
            and/or opinions by other third parties. The Fund did not contribute
            to the preparation of the third party material, which was
            independently prepared by a source that is not connected to the Fund
            and has not endorsed the Offering or the Fund. The Fund does not
            make any representations as to the accuracy, timeliness,
            suitability, completeness, or relevance of any information prepared
            by any unaffiliated third party and takes no responsibility
            therefore. The data presented herein were obtained from various
            third-party sources. While we believe the data to be reliable, no
            representation is made as to, and no responsibility, warranty or
            liability is accepted for the accuracy or completeness of such
            information.
          </p>
          <p>
            The REIT does not make any representations as to the accuracy,
            timeliness, suitability, completeness, or relevance of any
            information prepared by any unaffiliated third party and takes no
            responsibility, therefore. The data presented herein were obtained
            from various third-party sources. While we believe the data to be
            reliable, no representation is made as to, and no responsibility,
            warranty or liability is accepted for the accuracy or completeness
            of such information.
          </p>
          <h4 className="t-center t-medium">
            STRATCAP DIGITAL INFRASTRUCTURE REIT, INC.
          </h4>
          <h5 className="t-center t-small">Important Notices</h5>
          <p>
            This material (the "Material") is provided for informational and
            discussion purposes only and is not, and may not be relied on in any
            manner as, legal, business, financial, tax or investment advice or
            as an offer to sell or a solicitation of an offer to buy securities
            in StratCap Digital Infrastructure REIT Inc. (the “REIT"). A private
            placement of securities in the REIT will only be made pursuant to a
            confidential private placement memorandum (as may be amended or
            supplemented from time to time, the "PPM"), the REIT’s subscription
            documents, and the REIT’s limited liability company agreement
            (collectively, the "Offering Documents"), which will be furnished to
            qualified investors on a confidential basis at their request and
            should be reviewed in connection with any consideration of an
            investment in the REIT.
          </p>
          <p>
            No assurance can be given that the Fund’s investment objectives will
            be achieved. The Material does not contain all of the information
            and risk factors that would be important to an investor in making an
            investment decision and is not an offer to sell a security or the
            solicitation of an offer to buy a security.{" "}
            <span className="fw-7 underline">
              This document and its contents are strictly confidential.
            </span>
          </p>
          <p>
            Neither StratCap Digital Infrastructure REIT, Inc ("DIR") nor any
            affiliate of StratCap Digital Infrastructure Advisors II, LLC makes
            any undertaking to update the information in the Material, and such
            information may be superseded by, and is qualified in its entirety
            by, reference to the Offering Documents, which contain more detailed
            information about a prospective investment in the Fund and may be
            supplemented from time to time. To the extent that there is any
            inconsistency between the Material and the Offering Documents, the
            provisions of the Offering Documents control.
          </p>
          <p>
            There is no guarantee that an investor will receive any return on,
            or even a return of, an investor's capital. A discussion of material
            risks involved in an investment in the FUND is included in the PPM.
          </p>
          <p>
            Shares in this investment are illiquid, the sponsor is not required
            to provide a liquidity event and it may be difficult to sell your
            shares. Even if you are able to sell your shares, it may be at a
            substantial discount. Investing in our shares, and real estate in
            general, involves a high degree of risk. If we are unable to
            effectively manage the impact of these risks, we may not meet our
            investment objectives, and therefore, you should purchase these
            securities only if you can afford a complete loss of your
            investment. Please refer to the PPM for a detailed description of
            risk factors. There is no guarantee that an investor will receive
            any return on, or even a return of, an investor’s capital.
          </p>
          <h5 className="fw-7 t-small">
            Securities distributed through StratCap Securities, LLC, affiliated
            dealer manager and member of FINRA and SIPC.
          </h5>
          <div className="footer-flex">
            <div>
              <span className="d-gray-3">Distributed By:</span>
              <a
                href="http://stratcapsecurities.com/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={stratCapLogo} alt="sc logo" className="sc-logo" />
              </a>
            </div>
            <span className="d-gray-3">
              © 2021 Copyright All Rights Reserved | StratCap Digital
              Infrastructure RET, Inc.
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
<></>;
