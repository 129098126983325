import React, { useState, useContext } from "react";
import axios from "axios";
import { AuthContext } from "../App";

// MUI imports
import { withStyles } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";

const CssTextField = withStyles({
  root: {
    "& label.Mui-focused": {
      color: "#0096c8",
    },
    "& .MuiInput-underline:after": {
      borderBottomColor: "#0096c8",
    },
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: "red",
      },
      "&.Mui-focused fieldset": {
        borderColor: "#0096c8",
      },
    },
  },
})(TextField);

const InvestorBox = () => {
  const ROOT_URL = process.env.REACT_APP_API_URL;
  const PROD_KEY = process.env.REACT_APP_PRODUCT_KEY;

  const { dispatch } = useContext(AuthContext);

  const initialState = {
    controlNumber: "",
    passcode: "",
    isSubmitting: false,
    errorMessage: null,
  };

  const [data, setData] = useState(initialState);
  const [buttonText, setButtonText] = useState("LOG IN");

  const handleInputChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleFormSubmit = (e) => {
    console.log(data.controlNumber + data.passcode);
    e.preventDefault();
    setButtonText("");
    setData({
      ...data,
      isSubmitting: true,
      errorMessage: null,
    });
    const LoginInput = {
      product_key: PROD_KEY,
      control_no: data.controlNumber,
      authentication_code: data.passcode,
    };
    const spInput = {
      spName: "InvestorPortal_InvestorLogin",
      jsonInput: JSON.stringify(LoginInput),
    };
    const inputData = JSON.stringify(spInput);

    axios({
      method: "post",
      url: ROOT_URL,
      data: inputData,
      headers: { "Content-Type": "application/json" },
    })
      .then((response) => {
        setButtonText("SUCCESS!");
        const login_response = JSON.parse(response.data);
        console.log(login_response);
        if (login_response.Rows.length === 1) {
          sessionStorage.setItem(
            "investorMode",
            login_response.Rows[0].investor_mode
          );
          sessionStorage.setItem(
            "productId",
            login_response.Rows[0].product_id
          );
          sessionStorage.setItem(
            "profileId",
            login_response.Rows[0].profile_id
          );
          sessionStorage.setItem(
            "privatePlacementId",
            login_response.Rows[0].private_placement_id
          );
          sessionStorage.setItem(
            "reducedInvestorPortal",
            login_response.Rows[0].reduced_investor_portal
          );
          sessionStorage.setItem(
            "allowESignature",
            login_response.Rows[0].allow_esignature
          );
          sessionStorage.setItem("token", response.headers.authorization);
          sessionStorage.setItem("viewOnly", login_response.Rows[0].view_only);
          sessionStorage.setItem(
            "realLoggedInProfileId",
            login_response.Rows[0].real_logged_in_profile_id
          );
          dispatch({ type: "LOGIN", payload: login_response.Rows[0] });
        } else {
          setData({
            ...data,
            isSubmitting: false,
            errorMessage: login_response.error_message,
          });
          setButtonText("LOG IN");
        }
        return { resp: response };
      })

      .catch((error) => {
        console.log(error);
      });
  };

  const renderLoginButton = () => {
    if (buttonText === "LOG IN" || buttonText === "SUCCESS!") {
      return (
        <button disabled={data.isSubmitting} className="advisor-submit-button">
          {buttonText}
        </button>
      );
    } else {
      return (
        <button className="advisor-submit-button">
          <div className="login-loading-dots">
            <span></span>
            <span></span>
            <span></span>
          </div>
        </button>
      );
    }
  };

  return (
    <>
      <form onSubmit={handleFormSubmit}>
        <CssTextField
          label="Control Number"
          type="number"
          value={data.controlNumber}
          onChange={handleInputChange}
          name="controlNumber"
          className="login-input-box"
        />
        <CssTextField
          label="Passcode"
          type="password"
          value={data.passcode}
          onChange={handleInputChange}
          name="passcode"
          className="login-input-box"
        />
        {data.errorMessage && <p className="form-error">{data.errorMessage}</p>}
        {/* <input type="submit" value="Submit" /> */}
        <div className="login-button-wrapper">
          {renderLoginButton()}
        </div>
      </form>
      <h5 className="investor-box-heading-1">
        Log in by entering the control number and passcode indicated on the
        front cover of your Private Placement Memorandum ("PPM").
      </h5>
      <h5 className="investor-box-heading-2">
        To obtain a PPM, contact your financial advisor.
      </h5>
    </>
  );
};

export default InvestorBox;
