import React, { useState, useEffect } from "react";
import { NavHashLink as NavLink } from "react-router-hash-link";

import "./RtiButton.css";

import esigImg from "../../assets/images/esig_white.png";

const RtiButton = () => {
  const [innerWidth, setInnerWidth] = useState(window.innerWidth);

  const handleWindowSizeChange = () => {
    setInnerWidth(window.innerWidth);
  };

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  });

  const scrollWithOffset = (el) => {
    const yCoordinate = el.getBoundingClientRect().top + window.pageYOffset;
    const yOffset = -76;
    window.scrollTo({ top: yCoordinate + yOffset, behavior: "smooth" });
  };
  return (
    <>
      {innerWidth <= 600 ? (
        <NavLink
          smooth
          to="/#start-investing"
          activeClassName="selected"
          className="rti-button-mobile"
          scroll={(el) => scrollWithOffset(el)}
        >
          <img src={esigImg} alt="invest" className="rti-button-img-mobile" />
        </NavLink>
      ) : (
        <NavLink
          smooth
          to="/#start-investing"
          activeClassName="selected"
          className="rti-button"
          scroll={(el) => scrollWithOffset(el)}
        >
          Ready to Invest?
          <img src={esigImg} alt="invest" className="rti-button-img" />
        </NavLink>
      )}
    </>
  );
};

export default RtiButton;
