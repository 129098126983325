import React from "react";

// CSS
import "./FundOverview.css";

// images
import aaImg from "../../assets/images/OVERVIEW-ICON1.png";
import pcImg from "../../assets/images/OVERVIEW-ICON2.png";
import brImg from "../../assets/images/OVERVIEW-ICON3.png";
import deliverImg from "../../assets/images/OVERVIEW-ICON4.png";
import realizeImg from "../../assets/images/OVERVIEW-ICON5.png";

const FundOverview = () => {
  return (
    <>
      <div className="section-container" id="fund-overview">
        <div className="container-padding">
          <h1 className="section-title-slim">Fund Overview</h1>
          <div className="fo-content-container">
            <div className="fo-left-section">
              <h2 className="fw-4 uc ls-3">
                investment objectives<sup>1</sup>
              </h2>
              <div className="fo-objective-wrapper">
                <div className="fo-objective-card">
                  <img src={aaImg} className="fo-image-small" alt="acquire assets" />
                  <div className="fo-card-text-container">
                    <p className="fw-5 d-black no-margin">ACQUIRE ASSETS​</p>
                    <p className="no-margin">
                      Assemble and operate a diversified portfolio of digital
                      infrastructure assets​
                    </p>
                  </div>
                </div>
                <div className="fo-objective-card">
                  <img src={pcImg} className="fo-image-small" alt="preserve capital"/>
                  <div className="fo-card-text-container">
                    <p className="fw-5 d-black no-margin">PRESERVE CAPITAL​</p>
                    <p className="no-margin">
                      Preserve and protect investor capital.​
                    </p>
                  </div>
                </div>
                <div className="fo-objective-card">
                  <img src={brImg} className="fo-image-small" alt="balance risk" />
                  <div className="fo-card-text-container">
                    <p className="fw-5 d-black no-margin">BALANCE RISK​</p>
                    <p className="no-margin">
                      Provide attractive risk-adjusted returns to our
                      investors.​
                    </p>
                  </div>
                </div>
                <div className="fo-objective-card">
                  <img src={deliverImg} className="fo-image-small" alt="deliver tax-efficient income" />
                  <div className="fo-card-text-container">
                    <p className="fw-5 d-black no-margin">
                      DELIVER TAX-EFFICIENT INCOME<sup>2</sup>
                    </p>
                    <p className="no-margin">
                      Deliver tax-efficient income via regular monthly
                      distributions
                    </p>
                  </div>
                </div>
                <div className="fo-objective-card">
                  <img src={realizeImg} className="fo-image-small" alt="realize growth" />
                  <div className="fo-card-text-container">
                    <p className="fw-5 d-black no-margin">REALIZE GROWTH​​</p>
                    <p className="no-margin">
                      Realize growth in the value of our investments over time ​
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="fo-right-section">
              <h2 className="fw-4 uc t-center ls-3 white">offering details</h2>
              <div>
                <table className="fo-table white">
                  <tr>
                    <td>Offering Size:</td>
                    <td>Perpetual Life Offering</td>
                  </tr>
                  <tr>
                    <td>Investment Focus:</td>
                    <td>Digital infrastructure assets</td>
                  </tr>
                  <tr>
                    <td>Type:</td>
                    <td>Private Placement, Regulation D 506(b)</td>
                  </tr>
                  <tr>
                    <td>Structure:</td>
                    <td>Real Estate Investment Trust</td>
                  </tr>
                  <tr>
                    <td>Suitability:</td>
                    <td>Accredited investors only</td>
                  </tr>
                  <tr>
                    <td>Minimum investment:</td>
                    <td>$2,500</td>
                  </tr>
                  <tr>
                    <td>Legal Counsel:</td>
                    <td>Troutman Pepper</td>
                  </tr>
                  <tr>
                    <td>Auditor:</td>
                    <td>Deloitte & Touche LLP</td>
                  </tr>
                  <tr>
                    <td>Tax Form:</td>
                    <td>
                      The REIT expects to be treated as a REIT for tax purposes
                      and issue a 1099 tax form
                    </td>
                  </tr>
                </table>
              </div>
            </div>
          </div>
          <div className="fo-footer-container">
            <p className="fo-footer">
              1. There is no guarantee, implied or actual, that these objectives
              will be achieved. Actual results may differ. This is a speculative
              investment and could result in a total loss of investment. ​
            </p>
            <p className="fo-footer">
              2. Investors may have the benefit of deferring the potential
              income tax liability associated with distributions and
              reclassifying all or a portion of it to a lower capital gains
              rate. The depreciation associated with digital infrastructure
              assets is expected to result in either all, or a portion of
              distribution payments being classified as a return of capital,
              which would have the effect of lowering the overall cost basis.
              Upon liquidation of units, any gains based on the adjusted cost
              basis may be subject to capital gains, depreciation recapture or a
              combination thereof. Each individual’s tax situation and tax rate
              may be different. This information should not be construed as tax
              advice nor provides any opinion on, or discussion of, tax law.
              Investors should seek the advice of tax council. Tax rates may be
              subject to change. Distributions are not guaranteed and subject to
              change. Until proceeds from the offering are fully invested,
              distributions may be from a return of capital in anticipation of
              future cash flows which may reduce the capital invested in assets
              and negatively impact the investment. Please refer to the private
              placement memorandum for a complete discussion of costs and risks
              associated with the offering​
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default FundOverview;
