import React from "react";

const PrivacyPolicy = () => {
  return (
    <div>
      {/* <p>
        <i>LAST UPDATED: 7/9/2021</i>
      </p> */}
      <p>
        <strong>PRIVACY NOTICE</strong>
      </p>
      <p>
        This Privacy Notice is meant to help you understand the categories of
        personal information that StratCap Digital Infrastructure REIT, Inc. (“we”,
        “us”, or “our”) has collected or disclosed about you{" "}
        <strong>(“Personal Information”) </strong> within the past 12 months:
      </p>
      <p>(1) We collected the following categories of Personal Information:</p>
      <p className="margin_left">
        A. Identifiers, such as name, contact information and online
        identifiers;
      </p>
      <p className="margin_left">
        B. Personal information, as defined in the California customer records
        law, such as name, contact information and employment information;
      </p>
      <p className="margin_left">
        C. Characteristics of protected classifications under California or
        federal law, such as age and citizenship;{" "}
      </p>
      <p className="margin_left">
        D. Commercial information, such as transaction information and history;
      </p>
      <p className="margin_left">
        E. Internet or network activity information, such as browsing history,
        online behavior and interactions with our website;
      </p>
      <p className="margin_left">
        F. Geolocation data, such as device location and IP location;
      </p>
      <p className="margin_left">
        G. Audio, electronic, visual and similar information, such as call
        recordings created in connection with our business activities;
      </p>
      <p className="margin_left">
        H. Professional or employment-related information, such as employer or
        the entity with which you are affiliated; and
      </p>
      <p className="margin_left">
        I. Inferences drawn from any of the Personal Information listed above to
        create a profile or summary about, for example, an individual’s
        preferences and characteristics.
      </p>

      <p>
        We may use this Personal Information to operate, manage, and maintain
        our business, to provide our products and services, for our vendor
        management purposes, and to accomplish our business purposes and
        objectives, including, for example, using Personal Information to:
        develop, improve, and maintain our products and services; personalize,
        advertise, and market our products and services; conduct research,
        analytics, and data analysis; maintain our facilities and
        infrastructure; undertake quality and safety assurance measures; conduct
        risk and security control and monitoring; detect and prevent fraud;
        perform identity verification; perform accounting, audit, and other
        internal functions, such as internal investigations; comply with law,
        legal process, and internal policies; maintain records; and exercise and
        defend legal claims.
      </p>
      <p>We do not currently respond to browser do-not-track signals.</p>
      <p>
        (2) We disclosed the following Personal Information to third parties
        (such as our affiliates, our service providers and our business
        partners) for our operational business purposes:
      </p>
      <p className="margin_left">
        A. Identifiers, such as name, contact information and online
        identifiers;
      </p>
      <p className="margin_left">
        B. Personal information, as defined in the California customer records
        law, such as name, contact information and employment information;
      </p>
      <p className="margin_left">
        C. Characteristics of protected classifications under California or
        federal law, such as age and citizenship;
      </p>
      <p className="margin_left">
        D. Commercial information, such as transaction information and history;
      </p>
      <p className="margin_left">
        E. Internet or network activity information, such as browsing history,
        online behavior and interactions with our website;
      </p>
      <p className="margin_left">
        F. [Geolocation data, such as device location and IP location;
      </p>
      <p className="margin_left">
        G. Audio, electronic, visual and similar information, such as call
        recordings created in connection with our business activities;
      </p>
      <p className="margin_left">
        H. Professional or employment-related information, such as employer or
        the entity with which you are affiliated; and
      </p>
      <p className="margin_left">
        I. Inferences drawn from any of the Personal Information listed above to
        create a profile or summary about, for example, an individual’s
        preferences and characteristics.
      </p>
      <p>
        (3) We have not “sold” Personal Information for purposes of the
        California Consumer Privacy Act of 2018 (“CCPA”). For purposes of this
        Privacy Notice, “sold” or “sale” means the disclosure of Personal
        Information for monetary or other valuable consideration but does not
        include, for example, the transfer of Personal Information as an asset
        that is part of a merger, bankruptcy, or other disposition of all or any
        portion of our business.{" "}
      </p>
      <p>If you are a California resident, you may request that we:</p>
      <p>
        (1) Disclose to you the following information covering the 12 months
        preceding your request:
      </p>
      <p className="margin_left">
        o The categories of Personal Information we collected about you and the
        categories of sources from which we collected such Personal Information;
      </p>
      <p className="margin_left">
        o The specific pieces of Personal Information we collected about you;
      </p>
      <p className="margin_left">
        o The business or commercial purpose for collecting Personal Information
        about you;
      </p>
      <p className="margin_left">
        o The categories of Personal Information about you that we otherwise
        shared or disclosed, and the categories of third parties with whom we
        shared or to whom we disclosed such Personal Information (if
        applicable).
      </p>
      <p>(2) Delete Personal Information we collected from you.</p>
      <p>
        In some instances, we may decline to honor your request where an
        exception applies, and where the disclosure of Personal Information
        would adversely affect the rights and freedoms of another California
        resident. Nonetheless, you have the right to be free from unlawful
        discrimination for exercising your rights under the CCPA.
      </p>
      <p>
        <strong>How to Make Requests</strong>
      </p>
      <p>
        If you are a California resident, you may make a request for the
        disclosures described above or make a request to delete Personal
        Information we collected from you, by contacting us at:
      </p>
      <p className="margin_left">(1) (475) 282-0586 or</p>
      <p className="margin_left uc">
        (2) https://www.strategicwirelessinfrastructurefundii.com
      </p>
      <p>
        <strong>Changes to this Privacy Notice</strong>
      </p>
      <p>
        We may change or update this Privacy Notice from time to time. When we
        do, we will post the revised Privacy Notice on this page with a new
        “Last Updated” date.
      </p>
    </div>
  );
};

export default PrivacyPolicy;
